/*******************************************************************************
 * BASE STYLES
 */

@tailwind base;

/* for removing the highlight */
body {
  -webkit-tap-highlight-color: transparent;
}

/* set strong to semibold */
strong {
  @apply font-semibold;
}

/* hidden really means hidden */
[hidden] {
  display: none !important;
}

/* hide the red border for invalid fields in FF */
:-moz-ui-invalid {
  box-shadow: none;
}

/*******************************************************************************
 * COMPONENTS
 */

@tailwind components;

@import "./range-slider.css";

/* resposive size logo */
.responsive-logo {
  /* width: 145px; */
  height: 56px;
}
@screen lg {
  .responsive-logo {
    /* width: 175px; */
    height: 68px;
  }
}

/* using different font sizes for footer to match website */
footer .text-base {
  font-size: 14px;
}
footer .text-sm {
  font-size: 13px;
}
footer .text-xs {
  font-size: 11px;
}

/*******************************************************************************
 * UTILITIES
 */

/* forces outline to be hidden */
.outline-none\! {
  outline: none !important;
}

/* split words with hyphens according to lang attribute on html tag */
.hyphens-auto {
  hyphens: auto;
}

/* style some basic markup (used for info boxes) */
.styled-markup h3 {
  @apply text-2xl;
  @apply font-semibold;
  @apply mt-2;
  @apply mb-3;
}
.styled-markup p {
  @apply my-3;
}
.styled-markup ul {
  @apply ml-4;
  @apply list-disc;
  @apply list-outside;
}
.styled-markup li {
  @apply my-3;
}

.bg-magic {
  background: url("./images/bg-magic.gif") no-repeat center;
  background-size: cover;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

@tailwind utilities;
