/**
 * Range Slider Custom Element Styles
 *
 * adapted from:
 * @import "range-slider-element/dist/styles.css";
 */

range-slider {
  --element-height: 32px;
  --track-height: 4px;
  --thumb-size: 24px;
  position: relative;
  display: flex;
  align-items: center;
  height: var(--element-height);
  width: 100%;
  min-width: 130px;
  margin: 2px;
  overflow: visible;
  cursor: pointer;
  touch-action: none;
}

range-slider:focus {
  outline: 0;
}

range-slider[disabled] {
  filter: grayscale(1);
  opacity: 0.8;
}

range-slider:before {
  content: "";
  display: block;
  width: 100%;
  height: var(--track-height);
  border-radius: calc(var(--track-height) / 2);
  background: linear-gradient(
      theme("colors.cgpa-blue"),
      theme("colors.cgpa-blue")
    )
    0 / var(--value-percent, 0) 100% no-repeat theme("colors.white");
}

range-slider .thumb {
  background: theme("colors.white");
  border: 2px solid theme("colors.cgpa-blue");
  border-radius: 50%;
  width: var(--thumb-size);
  height: var(--thumb-size);
  position: absolute;
  bottom: calc(var(--element-height) / 2.0001 - var(--thumb-size) / 2);
  left: var(--value-percent, 0);
  margin-left: calc(var(--thumb-size) / 2 * -1);
  transition: transform 0.2s ease, background-color 0.2s;
  will-change: transform;
  pointer-events: none;
}

range-slider:hover .thumb {
  @apply bg-cgpa-blue-lightest;
}

range-slider:focus .thumb {
  @apply shadow-outline;
}

range-slider.touch-active .thumb-wrapper .thumb {
  box-shadow: none;
  transform: scale(1.35);
}

range-slider .thumb-wrapper {
  position: absolute;
  left: calc(var(--thumb-size) / 2);
  right: calc(var(--thumb-size) / 2);
  bottom: 0;
  height: 0;
  overflow: visible;
}
